




















import { Component, Vue } from 'vue-property-decorator';

import debounce from '../../../Modules/debounce.js';
import './scss/ButtonScrollUp.scss';

@Component({
    name: 'ButtonScrollUp',
})

export default class ButtonScrollUp extends Vue {

    created() {
        document.documentElement.style.setProperty('--button-scroll-up-duration', `0.${this.animationDuration}s`);
        this.checkScrollPosition();
        this.addScrollListener();
    }

    public btnDisplayed: boolean = false;
    public btnVisible: boolean = false;
    public animationDuration: number = 250;
    public scrollDistanceShow: number = 500;
    public debounceDelay: number = 200;

    public showBtn() {
        this.btnDisplayed = true;
        this.btnVisible = true;
    }

    public hideBtn() {
        this.btnVisible = false;
        setTimeout(() => {
            this.btnDisplayed = false;
        }, this.animationDuration);
    }

    private addScrollListener() {
        document.addEventListener('scroll', () => {
            this.debouncedScroll();
        });
    }

    private checkScrollPosition() {
        if (window.scrollY <= this.scrollDistanceShow) {
            this.hideBtn();
        } else {
            this.showBtn();
        }
    }

    private scrollUp() {
        window.scrollTo(0,0);
    }

    get debouncedScroll() {
        return debounce(this.checkScrollPosition, this.debounceDelay);
    }
}
